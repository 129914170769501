.container-gamedetail{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    // background: url('../../assets/images/game-detail-bg.png') no-repeat;
    background-size: 100%, 100% 100vh;
    .bg{
        position: absolute;
        width: 100%;
        max-width: 100%;
        height: 100vh;
        z-index: 1;
        background-size: 100%, 100%!important;
        opacity: 0.8;
    }
    &>div{
        max-width: 1240px;
        margin: 0 auto;
        padding: 3rem 4rem;
        position: relative;
        z-index: 10;
        @media (max-width: 768px) {
            padding: 1rem;
        }
    }
    .more-img{
        width: 100%;
        img{
            border-radius: 10px;
        }
    }
    .card{
        position: relative;
        overflow: hidden;
        cursor: pointer;
        img{
            object-fit: cover;
        }
        .tag{
            height: 28px;
            width: auto;
            position: absolute;
            top: 6px;
            left: 8px;
        }
        .name{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 114px 20px 36px 20px;
            background: linear-gradient(180deg, rgba(0, 4, 78, 0) 0%, #121439 100%);
            h3{
                margin-bottom: 0;
                font-size: 30px;
                font-weight: 700;
                line-height: 1.1;
                word-break:keep-all;
                background: linear-gradient(180deg, #75F7FF 24%, #E3CDFF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;  
            }
        }
    }
    .section1{
        width: 100%;
        button{
            cursor: pointer;
        }
        h1{
            font-size: 20px;
            font-weight: 600;
            padding-top: 80px;
            color: #98E6FF;  
            @media (max-width: 768px) {
                padding-top: 20px;
            }
            span{
                color: #fff;
                font-size: 20px;
            } 
        }
        .intro{
            display: grid;
            grid-template-columns: 50% 50%;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            overflow: hidden;
            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }
            .head{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                a{
                    color:#fff;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 5px 10px;
                    border-radius: 20px;
                    border: 1px solid #fff;
                }
            }
            &>div{
                height: 380px;
                @media (max-width: 768px) {
                    height: auto;
                }
                &:first-of-type{
                    width: 100%;
                    img{
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                &:last-of-type{
                    padding: 40px;
                    background: linear-gradient(90deg, #01044E 0%, #040520 100%);
                    @media (max-width: 768px) {
                        padding: 20px;
                    }
                }
            }
            h2{
                font-size: 30px;
                color: #fff;
                font-weight: 700;
                margin-bottom: 0;
            }
            p{
                color: #fff;
            }
            button{
                background-color: #A7F46A;
                color: #020035;
                font-size: 14px;
                font-weight: 600;
                border: none;
                padding: 10px 30px;
                border-radius: 30px;
            }
        }
        .info{
            display: flex;
            justify-content: space-between;
            color: #949CB9;
            margin-bottom: 20px;
            span{
                color: #fff;
            }
            img{
                width: 25px;
                height: 25px;
                margin-left: 10px;
            }
        }
    }
    .section2{
        &>div{
            background: linear-gradient(270deg, #030520 61.06%, #00034D 100%);
            border-left: 4px solid #0C2A96;
            padding: 25px 37px;
            @media (max-width: 768px) {
                padding: 20px;
            }
        }
        h2{
            color: #98E6FF;
            font-size: 30px;
            font-weight: 600;
        }
        ol{
            padding-left: 20px;
            li{
                font-size: 16px;
                font-weight: 400;
                color: #fff;
            }
        }
    }
    .section3{
        padding-top: 0;
        &>div{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
            &>div{
                border-radius: 10px;
            }
            img{
                height: 194px;
                width: 100%;
                object-fit: cover;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        } 
    }
    .section4{
        h2{
            color: #98E6FF;
            font-size: 30px;
            font-weight: 600;
        }
        h3{
            font-size: 24px!important;
        }
        &>div{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
            &>div{
                border-radius: 10px;
            }
            img{
                width: 100%;
                height: 330px;
                object-fit: cover;
                @media (max-width: 768px) {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}