.container-guidedetail{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 2, 40, 0) 0%, #000228 61.31%), url('../../assets/images/EternaLegacyImg.png') no-repeat;
    background-size: 100vh,100%;
    &>div{
        max-width: 1240px;
        margin: 0 auto;
        padding: 3rem 4rem;
        @media (max-width: 768px) {
            padding: 1rem;
        }
    }
    .card{
        position: relative;
        overflow: hidden;
        cursor: pointer;
        img{
            object-fit: cover;
        }
        .name{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 114px 20px 36px 20px;
            background: linear-gradient(180deg, rgba(0, 4, 78, 0) 0%, #121439 100%);
            h3{
                margin-bottom: 0;
                font-size: 30px;
                font-weight: 700;
                line-height: 1.1;
                word-break:keep-all;
                background: linear-gradient(180deg, #75F7FF 24%, #E3CDFF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;  
            }
        }
    }
    .section1{
        width: 100%;
        h1{
            font-size: 20px;
            font-weight: 600;
            padding-top: 80px;
            color: #98E6FF; 
            @media (max-width: 768px) {
                padding-top: 20px;
            }
            span{
                color: #fff;
                font-size: 20px;
            } 
        }
        p{
            color: #fff;
            margin-top: 20px;
        }
        .intro{
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            &>div{
                &:first-of-type{
                    img{
                        width: 100px;
                        @media (max-width: 768px) {
                            width: 40px;
                        }
                    }
                }
                &:last-of-type{
                    a{
                        color:#fff;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 5px 10px;
                        border-radius: 20px;
                        border: 1px solid #fff;
                    }
                }
            }
            h2{
                font-size: 48px;
                display: inline-block;
                margin-left: 20px;
                background: linear-gradient(180deg, #75F7FF 24%, #E3CDFF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;  
                @media (max-width: 768px) {
                    font-size: 20px;
                    margin-left: 10px;
                }
            }
            button{
                background-color: #A7F46A;
                color: #020035;
                font-size: 14px;
                font-weight: 600;
                border: none;
                padding: 10px 30px;
                border-radius: 30px;
            }
        }
        .info{
            display: flex;
            justify-content: space-between;
            color: #949CB9;
            margin-bottom: 20px;
            span{
                color: #fff;
            }
            img{
                width: 25px;
                height: 25px;
            }
        }
    }
    .section2{
        h2{
            color: #98E6FF;
            font-size: 30px;
            font-weight: 600;
        }
        .steps{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
            &>div{
                background: linear-gradient(270deg, #030520 61.06%, #00034D 100%);
                border-left: 4px solid #0C2A96;
                padding: 25px 37px;
                @media (max-width: 768px) {
                    padding: 20px;
                }
            }
        }
        h3{
            font-size: 22px;
            font-weight: 700;
            color: #fff;
        }
        p{
            font-size: 16px;
            font-weight: 500;
            color: #949CB9;
        }
    }
}