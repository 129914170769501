.container-guide{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    background: url('../../assets/images/bg1.jpg') no-repeat;
    background-size: 100%;
    background-position: top center;
    &>div{
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 4rem;
        @media (max-width: 768px) {
            padding: 0 1rem;
        }
        &:not(:first-of-type){
            margin-top: 40px;
        }
    }
    .card{
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        img{
            object-fit: cover;
        }
        .name{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 38px 17px 17px 17px;
            background: linear-gradient(180deg, rgba(0, 4, 78, 0) 0%, #121439 100%);
            h3{
                margin-bottom: 0;
                font-size: 26px;
                font-weight: 600;
                line-height: 1.1;
                word-break:keep-all;
                background: linear-gradient(180deg, #75F7FF 24%, #E3CDFF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;  
            }
        }
    }
    .section1{
        h1{
            font-size: 64px;
            font-weight: 600;
            text-align: center;
            padding-top: 140px;
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.7) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;  
            margin-bottom: 0;              
        }
        p{
            font-size: 24px;
            color: #fff;
            font-weight: 500;
            text-align: center;
        }
        .ant-input-search{
            display: block;
            margin: 0 auto;
            width: 538px;
            input{
                background: #07112880;
                height: 60px;
                border-radius: 30px 0 0 30px;
                border: 1px solid #97B7EF;
                border-right: none;
            }
            .ant-input-group-addon{
                background: #07112880;
                border-radius: 0 30px 30px 0;
                .ant-input-search-button{
                    height: 60px;
                    width: 60px;
                    background: #07112880;
                    border-radius: 0 30px 30px 0;
                    color: #97B7EF!important;
                    border-color: #97B7EF;
                    border-left: none;
                    svg{
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .section2{
        width: 100%;
        .games{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
            img{
                width: 100%;
                height: 330px;
                object-fit: cover;
            }
            .tag{
                height: 28px;
                width: auto;
                position: absolute;
                top: 6px;
                left: 8px;
            }
        }
    }
}