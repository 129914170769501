@import '~antd/lib/style/color/colorPalette.less';
@import '~antd/dist/antd.less';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@primary-color: #A7F46A;
@border-radius: 10;
html, body, input, textarea, button {
    font-family: 'Inter', sans-serif;
    letter-spacing: -0.018em;
    font-display: fallback;
}
iframe{
    border:none;
    display: none;
}
.up{
    color: #14B8A6;
}
.down{
    color: #F64242;
}
:root {
    --background: #000228;
    --background-secondary: #F4F4F5;
    --primary-color: #A7F46A;
    --text-primary: #18181B;
    --text-secondary: #71717A;
    --layout-header-background: #fff;
    --rink-card-background: #F4F4F5;
    --border-color: #E4E4E7;
    --badge-background: #F4F4F5;
    --green: #14B8A6;
    --red: #F64242;
    --background-home: #F9FAFB;
}
[data-theme='dark'] {
    --background: #000228;
    --background-secondary: #2D3035;
    --primary-color: #A7F46A;
    --text-primary: #fff;
    --text-secondary: #fafafa;
    --accent: #71717A;
    --layout-header-background: #27272A;
    --rink-card-background: #3F3F46;
    --border-color: #272949;
    --badge-background: #5542F6;
    --green: #14B8A6;
    --red: #F64242;
}
#root{
    background: var(--background);
}
.ant-card{
    background: none!important;
}
.ant-empty-normal{
    color: var(--text-primary);
}
html,body{
    background: var(--background);
    color: var(--text-primary);
}
.headerDropdown{
    width: 150px;
}
